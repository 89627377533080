import { CopyOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";
import { items_op_scale_animation, opacity_anime, scale_anime } from "../../assets/animations";
import { constants } from "../../assets/constants";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/svg/accountSVG/arrow-right.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/svg/accountSVG/closeIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/accountSVG/edit.svg";
import { ReactComponent as OkIcon } from "../../assets/images/svg/accountSVG/okIcon.svg";
import { ReactComponent as PasswordCheckIcon } from "../../assets/images/svg/accountSVG/password-check.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/svg/accountSVG/trash.svg";
import { ReactComponent as TwoFactorIcon } from "../../assets/images/svg/accountSVG/two_factor.svg";
import { ReactComponent as WarningIcon } from "../../assets/images/svg/accountSVG/warning.svg";
import CloseIcon from "../../assets/images/svg/CloseIcon";
import { ReactComponent as AppleIcon } from "../../assets/images/svg/modalsSVG/apple.svg";
import { ReactComponent as WindowsIcon } from "../../assets/images/svg/modalsSVG/windows.svg";
import tfa_logo from "../../assets/images/tfa_google.png";
import { helpers } from "../../helpers";
import { useAppDispatch, useAppSelector } from "../../store";
import {
    removeTwoFactorAuthenticationAsync,
    resetTfaConfirmCode,
    resetTfaInfos,
    setupTwoFactorAuthenticationAsync,
    verifyTwoFactorAuthenticationAsync,
} from "../../store/features/accountSlice";
import { resetModalStateToInitialRedux, setModalTypeRedux } from "../../store/features/modalSlice";
import {
    changeNameAsync,
    changePasswordAsync,
    deleteAccountAsync,
    removeOtherSessionAsync,
    resetByDefaultSettingsRedux,
    resetNameErrorRedux,
    resetNameSaved,
    resetPasswordForDeleteError,
} from "../../store/features/settingsSlice";
import { resetFormErrorsRedux } from "../../store/features/signupSlice";
import { ChangeEmailInitialStateTypes, ChangeFormDataTypes, HelperModalInitialData, TfaInitialStateTypes } from "../../types";
import HelperSwitch from "../HelperSwitch";
import Input from "../Input";
import ChangeEmail from "./components/ChangeEmail";
import ChoosePlanMethod from "./components/ChoosePlanMethod";
import ConvertSubs from "./components/ConvertSubs";
import MoreBenefits from "./components/MoreBenefits";
import PayVIAQr from "./components/PayViaQR";
import styles from "./modals.module.scss";

const helperModalInitialData: HelperModalInitialData = {
    name: "",
    email: "",
    password: "",
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
};

const tfaInitialState: TfaInitialStateTypes = {
    canScan: true,
    isVerifyPageOpen: false,
    confirmCode: "",
    isCopied: false,
};

export const changeEmailInitialState: ChangeEmailInitialStateTypes = {
    email: "",
    password: "",
    isPasswordConfirmed: false,
    passwordLocalError: "",
    newEmail: "",
    newEmailLocalError: "",
    confirmActualEmail: "",
    confirmNewEmail: "",
};

const Modals = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { type, configs } = useAppSelector((state) => state.modal);
    const {
        user,
        token,
        tfaInfos: { secretKey, dataURL },
        isTfaConfirmCodeInvalid,
        activeSub,
    } = useAppSelector((state) => state.account);
    const { emailError } = useAppSelector((state) => state.signUp);
    const { nameError, nameSaved, isPasswordForDeleteAccountIncorrect, passwordStatus } = useAppSelector((state) => state.settings);
    const { modalTypes, emailPattern } = constants;

    const [modalFormData, setModalFormData] = useState(helperModalInitialData);

    const systemsData = useMemo(
        () => [
            {
                id: "1",
                name: t("modals.system_masq.windows"),
                link: process.env.REACT_APP_WINDOWS_LINK?.replaceAll("version", process.env.REACT_APP_APP_VERSION + ""),
                icon: <WindowsIcon />,
            },
            {
                id: "2",
                name: t("modals.system_masq.mac_intel"),
                link: process.env.REACT_APP_MAC_INTEL_LINK?.replaceAll("version", process.env.REACT_APP_APP_VERSION + ""),
                icon: <AppleIcon />,
            },
            {
                id: "3",
                name: t("modals.system_masq.mac_m"),
                link: process.env.REACT_APP_MAP_M_LINK?.replaceAll("version", process.env.REACT_APP_APP_VERSION + ""),
                icon: <AppleIcon />,
            },
        ],
        [t]
    );

    const [nameLocalError, setNameLocalError] = useState("");

    const [tfaFormData, setTfaFormData] = useState(tfaInitialState);
    const [changeEmailFormData, setChangeEmailFormData] = useState(changeEmailInitialState);

    const changeEmailData = ({ type, value }: ChangeFormDataTypes) => {
        emailError && dispatch(resetFormErrorsRedux({ type }));
        let err = "";
        if (type === "newEmail" && !emailPattern.test(value) && value.length > 0) {
            err = t("signUp.email_local_error");
        }
        setChangeEmailFormData({ ...changeEmailFormData, [type]: value, [type + "LocalError"]: err });
    };

    const changeTfaData = (type: string, value: string | boolean) => {
        setTfaFormData({ ...tfaFormData, [type]: value });
    };

    const changeDataValues = ({ type, value }: { type: string; value: string }) => {
        const regexp = /^[a-zA-Z\d\s]*$/;
        if (type === "name") {
            if (regexp.test(value) || value === "") {
                setModalFormData({ ...modalFormData, [type]: value });

                return;
            }
            if (value.length < 3) {
                setNameLocalError(t("signUp.name_local_error"));
                return;
            }
        }
        setModalFormData((data) => ({ ...data, [type]: value }));
    };

    const isNewPasswordCharacterCorrect = modalFormData.newPassword.length > 7;
    const isNewPasswordContainSymbol = helpers.isPasswordContainSymbolOrNumber(modalFormData.newPassword);
    const isNewPasswordCorrect = isNewPasswordCharacterCorrect && isNewPasswordContainSymbol;
    const areNewPasswordsSame = modalFormData.newPassword === modalFormData.newPasswordConfirm && modalFormData.newPassword;

    const resetModalData = () => setModalFormData(helperModalInitialData);
    const closeModal = () => dispatch(resetModalStateToInitialRedux());
    const deleteAccountModal = () => dispatch(setModalTypeRedux({ type: modalTypes.deleteAcc }));
    const endAllSessionModal = () => dispatch(setModalTypeRedux({ type: modalTypes.endAllSession }));
    const changePassowordModal = () => dispatch(setModalTypeRedux({ type: modalTypes.passwordChange }));
    const changeEmailModal = () => dispatch(setModalTypeRedux({ type: modalTypes.emailChange }));
    const securtyModal = () => dispatch(setModalTypeRedux({ type: modalTypes.security }));
    const openTfaModal = () => dispatch(setModalTypeRedux({ type: modalTypes.tfa }));

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(secretKey);
        changeTfaData("isCopied", true);
        setTimeout(() => changeTfaData("isCopied", false), 2000);
    };
    const deleteMyAccount = () => {
        dispatch(resetPasswordForDeleteError());
        dispatch(deleteAccountAsync({ token, password: modalFormData.password }));
        setModalFormData({ ...modalFormData, password: "" });
    };
    const toggleConfirmCode = (value: string) => {
        dispatch(resetTfaConfirmCode());
        (new RegExp("[0-9]").test(value) || !value) && setTfaFormData({ ...tfaFormData, confirmCode: value });
    };
    const removeAllSession = () => {
        dispatch(removeOtherSessionAsync({ token }));
    };

    const removeTfa = () => dispatch(removeTwoFactorAuthenticationAsync({ token }));

    const handleChangePassword = () => {
        dispatch(
            changePasswordAsync({
                token,
                oldPassword: modalFormData.oldPassword,
                newPassword: modalFormData.newPassword,
                resetModalFn: resetModalData,
            })
        );
    };

    const handleModalClickForClose = (e: React.MouseEvent<HTMLElement>, isImportProfile?: boolean, isChooseMethod?: boolean) => {
        const targetId = (e.target as HTMLElement)?.id;
        if (targetId === "close_") {
            dispatch(resetModalStateToInitialRedux());
            type === modalTypes.tfa && setTfaFormData(tfaInitialState);
            if (type === modalTypes.emailChange) {
                setChangeEmailFormData(changeEmailInitialState);
                dispatch(resetByDefaultSettingsRedux());
            }
        }
    };

    const handleVerifyTfa = () => {
        dispatch(
            verifyTwoFactorAuthenticationAsync({
                token,
                confirmCode: tfaFormData.confirmCode,
            })
        );
    };

    const closeTfaModal = () => {
        setTfaFormData(tfaInitialState);
        closeModal();
    };

    const closeChangeEmailModal = () => {
        setChangeEmailFormData(changeEmailInitialState);
        closeModal();
    };

    useEffect(() => {
        if (type === modalTypes.settings) {
            setModalFormData({ ...modalFormData, name: user.name, email: user.email });
        }
    }, [type]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (modalFormData.name.length > 2 && modalFormData.name !== user.name) {
            setNameLocalError("");
            timeout = setTimeout(() => {
                dispatch(changeNameAsync({ token, name: modalFormData.name }));
            }, 200);
        }
        return () => clearTimeout(timeout);
    }, [modalFormData.name]);

    useEffect(() => {
        return () => {
            dispatch(resetNameErrorRedux());
            setModalFormData(helperModalInitialData);
            dispatch(resetTfaInfos());
            setTfaFormData(tfaInitialState);
            setChangeEmailFormData(changeEmailInitialState);
        };
    }, []);

    useEffect(() => {
        if (nameSaved) {
            setTimeout(() => {
                dispatch(resetNameSaved());
            }, 1500);
        }
    }, [nameSaved]);

    useEffect(() => {
        type === modalTypes.tfa && dispatch(setupTwoFactorAuthenticationAsync({ token }));
    }, [type]);

    switch (type) {
        case modalTypes.download:
            return (
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={opacity_anime}
                    custom={0.3}
                    className={styles.modals_wrapper}
                    onClick={handleModalClickForClose}
                    id="close_"
                >
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.system_masq}>
                            <motion.p
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={scale_anime}
                                className={styles.subTitle}
                            >
                                {t("sundry.download")}
                            </motion.p>
                            <motion.p
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                variants={scale_anime}
                                className={styles.title}
                            >
                                {t("modals.system_masq.system")}
                            </motion.p>
                            <div className={styles.systems_wrapper}>
                                {systemsData.map(({ id, name, link, icon }, i) => {
                                    if (link) {
                                        return (
                                            <motion.div
                                                initial="hidden"
                                                whileInView="visible"
                                                variants={items_op_scale_animation}
                                                custom={i * 3}
                                                className={styles.system_card}
                                                key={id}
                                            >
                                                {icon}
                                                <div className={styles.texts}>
                                                    <p className={styles.system}>{name}</p>
                                                    <p className={styles.about_system}>
                                                        {t("modals.system_masq.version")} {process.env.REACT_APP_APP_VERSION}
                                                    </p>
                                                </div>
                                                <a href={link} className={styles.download}>
                                                    {t("sundry.download")}
                                                </a>
                                            </motion.div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </motion.div>
            );
        case modalTypes.settings:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.account}>
                            <div className={styles.info}>
                                <p className={styles.subTitle}>{t("sundry.account")}</p>
                                <p className={styles.title}>{t("sundry.settings")}</p>
                            </div>
                            <div className={styles.inputs_wrapper}>
                                <label className={styles.input_wrapper}>
                                    <span className={styles.label}>{t("account.your_username")}</span>
                                    <input
                                        type={type}
                                        placeholder={t("account.your_username")}
                                        onChange={(e) => changeDataValues({ type: "name", value: e.target.value })}
                                        value={modalFormData.name}
                                        className={`${styles.input} ${(nameError || nameLocalError) && styles.error}`}
                                    />
                                    <div className={styles.show_edit}>
                                        <EditIcon />
                                    </div>
                                    {(nameError || nameLocalError) && <span className={styles.error_message}>{nameError || nameLocalError}</span>}
                                    {nameSaved && <p className={styles.saved_info}>{t("sundry.saved")}</p>}
                                </label>
                                <button className={styles.btn} onClick={changeEmailModal}>
                                    <span className={styles.btn_icon_name}>
                                        <EditIcon />
                                        {t("account.change_email")}
                                    </span>
                                    <span className={styles.btn_type}>
                                        <ArrowRightIcon />
                                    </span>
                                </button>
                            </div>
                            <button className={styles.black_btn} onClick={closeModal}>
                                {t("sundry.save")}
                            </button>
                            <button className={styles.delete} onClick={deleteAccountModal}>
                                {t("account.delete_account")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        case modalTypes.deleteAcc:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.delete_acc}>
                            <div className={styles.big_border}>
                                <div className={styles.smallBorder}>
                                    <div className={styles.icon_wrapper}>
                                        <TrashIcon />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.delete_info}>
                                <p className={styles.title}>{t("account.delete_your_acc")}</p>
                                <p className={styles.subTitle}>{t("account.all_data_removed")}</p>
                            </div>
                            <Input
                                type="password"
                                name="password"
                                value={modalFormData.password}
                                onChange={changeDataValues}
                                placeholder={t("account.enter_your_password")}
                                label=""
                                errorWithoutMessage={isPasswordForDeleteAccountIncorrect}
                            />
                            <button className={styles.red_btn} onClick={deleteMyAccount}>
                                {t("account.yes_delete")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        case modalTypes.security:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.safety}>
                            <div className={styles.info}>
                                <p className={styles.subTitle}>{t("sundry.settings")}</p>
                                <p className={styles.title}>{t("account.safety")}</p>
                            </div>
                            <div className={styles.btns_wrapper}>
                                <button className={styles.btn} onClick={changePassowordModal}>
                                    <span className={styles.btn_icon_name}>
                                        <PasswordCheckIcon />
                                        {t("account.change_password")}
                                    </span>
                                    <span className={styles.btn_type}>
                                        <ArrowRightIcon />
                                    </span>
                                </button>
                                <button className={styles.btn}>
                                    <span className={styles.btn_icon_name}>
                                        <TwoFactorIcon />
                                        {t("account.two_step")}
                                    </span>
                                    <span className={styles.btn_type}>
                                        <HelperSwitch checked={user.tfaEnabled} onChange={() => (user.tfaEnabled ? removeTfa() : openTfaModal())} />
                                    </span>
                                </button>
                            </div>
                            <button className={styles.end_session} onClick={endAllSessionModal}>
                                {t("account.end_all_session")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        case modalTypes.endAllSession:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.end_sessions_wrapper}>
                            <div className={styles.big_border}>
                                <div className={styles.smallBorder}>
                                    <div className={styles.icon_wrapper}>
                                        <WarningIcon />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <p className={styles.title}>{t("account.need_end_sessions")}</p>
                                <p className={styles.subTitle}>{t("account.will_be_end")}</p>
                            </div>
                            <button className={styles.black_btn} onClick={removeAllSession}>
                                {t("account.yes_end_sessions")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        case modalTypes.passwordChange:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.change_password}>
                            <div className={styles.info}>
                                <p className={styles.subTitle}>{t("account.safety")}</p>
                                <p className={styles.title}>{t("account.change_password")}</p>
                            </div>
                            <div className={styles.inputs_wrapper}>
                                <Input
                                    type="password"
                                    label={t("account.old_password")}
                                    name="oldPassword"
                                    onChange={changeDataValues}
                                    value={modalFormData.oldPassword}
                                    placeholder={t("account.old_password")}
                                    isError={passwordStatus}
                                />
                                <Input
                                    type="password"
                                    label={t("account.new_password")}
                                    name="newPassword"
                                    onChange={changeDataValues}
                                    value={modalFormData.newPassword}
                                    placeholder={t("account.new_password")}
                                    disabled={modalFormData.oldPassword.length < 3}
                                />
                                <div className={styles.errors_wrapper}>
                                    <p className={styles.error_rows}>
                                        {isNewPasswordCharacterCorrect ? <OkIcon /> : <ErrorIcon />} {t("signUp.password_at")}
                                    </p>
                                    <p className={styles.error_rows}>
                                        {isNewPasswordContainSymbol ? <OkIcon /> : <ErrorIcon />} {t("signUp.password_contains")}
                                    </p>
                                </div>
                                <Input
                                    type="password"
                                    label={t("account.confirm_new_pass")}
                                    name="newPasswordConfirm"
                                    onChange={changeDataValues}
                                    value={modalFormData.newPasswordConfirm}
                                    placeholder={t("account.confirm_new_pass")}
                                    disabled={!isNewPasswordCorrect}
                                />
                            </div>
                            <button className={styles.black_btn} disabled={!areNewPasswordsSame} onClick={handleChangePassword}>
                                {t("sundry.save")}
                            </button>
                            <button className={styles.blat_text_btn} onClick={securtyModal}>
                                {t("sundry.go_back")}
                            </button>
                        </div>
                    </div>
                </div>
            );
        case modalTypes.tfa:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.tfa_main_wrapper}>
                        <div className={styles.tfa_close_icon} onClick={closeTfaModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.tfa_icon_place}>
                            <img src={tfa_logo} alt="Logo" />
                        </div>
                        <div className={styles.tfa_info_place}>
                            <h2 className={styles.tfa_header}>{t("tfa.set_up")}</h2>
                            {!tfaFormData.isVerifyPageOpen ? (
                                <>
                                    <ul className={styles.tfa_docs}>
                                        <li>{t("tfa.get_auth")}</li>
                                        <li>{t("tfa.in_app")}</li>
                                        <li>{t("tfa.choose_scan")}</li>
                                    </ul>
                                    <div className={styles.tfa_code_place}>
                                        {tfaFormData.canScan ? (
                                            <div className={styles.tfa_qr_code}>
                                                <div className={styles.tfa_qr_img_wrapper}>{!!dataURL && <img src={dataURL} alt="Qr Code" />}</div>
                                                <button className={styles.tfa_can_not_scan} onClick={() => changeTfaData("canScan", false)}>
                                                    {t("tfa.cant_scan")}
                                                </button>
                                            </div>
                                        ) : (
                                            <div className={styles.tfa_security_key_wrapper}>
                                                <div className={styles.tfa_security_key}>
                                                    <p>{secretKey}</p>
                                                    <div className={styles.tfa_copy_icon} onClick={handleCopyClick}>
                                                        <CopyOutlined />
                                                        {tfaFormData.isCopied && <span className={styles.copied}>{t("sundry.copy")}</span>}
                                                    </div>
                                                </div>
                                                <button onClick={() => changeTfaData("canScan", true)}>{t("tfa.scan_qr")}</button>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.tfa_btn_place}>
                                        <button className={styles.tfa_cancel} onClick={closeTfaModal}>
                                            {t("sundry.cancel")}
                                        </button>
                                        <button className={styles.tfa_next} onClick={() => changeTfaData("isVerifyPageOpen", true)}>
                                            {t("sundry.next")}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className={styles.tfa_verify_page}>
                                    <p className={styles.tfa_verify_text}>{t("tfa.once_scanned")}</p>
                                    <div className={styles.tfa_verify_code_place}>
                                        <VerificationInput
                                            length={6}
                                            autoFocus={true}
                                            onChange={toggleConfirmCode}
                                            value={tfaFormData.confirmCode}
                                            classNames={{
                                                container: styles.vf_container,
                                                character: `${styles.vf_character} ${isTfaConfirmCodeInvalid && styles.active}`,
                                                characterInactive: styles.vf_character_inactive,
                                                characterSelected: `${styles.vf_character_selected} ${isTfaConfirmCodeInvalid && styles.active}`,
                                            }}
                                        />
                                    </div>
                                    <div className={styles.tfa_verify_btn}>
                                        <button
                                            className={styles.tfa_verify_save}
                                            disabled={tfaFormData.confirmCode.length !== 6 && true}
                                            onClick={handleVerifyTfa}
                                        >
                                            {t("tfa.verify_and_save")}
                                        </button>
                                        <button className={styles.tfa_veirfy_page_cancel} onClick={closeTfaModal}>
                                            {t("sundry.cancel")}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        case modalTypes.emailChange:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeChangeEmailModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.change_email_wrapper}>
                            <div className={styles.info}>
                                <p className={styles.subTitle}>{t("account.change_email")}</p>
                                <p className={styles.title}>{t("sundry.settings")}</p>
                            </div>
                            <ChangeEmail
                                changeEmailFormData={changeEmailFormData}
                                setChangeEmailFormData={setChangeEmailFormData}
                                changeFormData={changeEmailData}
                            />
                        </div>
                    </div>
                </div>
            );
        case modalTypes.useQrCode:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeChangeEmailModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.qr_code_info_wrapper}>
                            <div className={styles.info}>
                                <p className={styles.subTitle}>{configs?.selectedCurrencyName}</p>
                                <p className={styles.title}>{t("buy_change_sub.payment_via_qr")}</p>
                            </div>
                            <PayVIAQr />
                        </div>
                    </div>
                </div>
            );
        case modalTypes.chooseSubPlanMethod:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeChangeEmailModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.choose_plan_method}>
                            <div className={styles.info}>
                                <p className={styles.title}>{t("convert_subs.choose_method")}</p>
                            </div>
                            <ChoosePlanMethod />
                        </div>
                    </div>
                </div>
            );
        case modalTypes.convertSubs:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <div className={styles.modal_main}>
                        <div className={styles.close} onClick={closeChangeEmailModal}>
                            <CloseIcon />
                        </div>
                        <div className={styles.convert_subs}>
                            <div className={styles.info}>
                                <p className={styles.subTitle}>{t("convert_subs.subtitle")}</p>
                                <p className={styles.title}>
                                    {t("convert_subs.title")} {activeSub.type} {t("sundry.to")} {configs?.subName}
                                </p>
                            </div>
                            <ConvertSubs />
                        </div>
                    </div>
                </div>
            );
        case modalTypes.moreBenefitsShow:
            return (
                <div className={styles.modals_wrapper} id="close_" onClick={handleModalClickForClose}>
                    <MoreBenefits />
                </div>
            );
        default:
            return null;
    }
};

export default Modals;
